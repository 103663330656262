/*
 * @Descripttion : 路由和权限处理
 * @version      :
 * @Author       : ml
 * @Date         : 2020-08-27 17:13:07
 * @LastEditors  : ml
 * @LastEditTime : 2020-09-04 14:11:22
 */
import router from '../router'
import store from '../store'

import { loadRouter } from '../config/userConfig'

router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面title */
	if (to.meta.title) {
		document.title =
			process.env.VUE_APP_PROJECT_NAME + ' - ' + to.meta.title
	}

	let token = localStorage.getItem('cbz_token')
	if (token != null && token.trim().length != 0) {
		if (!store.state.userStore.id && from.path !== to.path) {
			// 登录信息丢失后重新获取
			loadRouter(false)
		}
		next()
	} else {
		// 未登录
		if (to.path === '/') {
			next()
		} else {
			next({ path: '/' })
		}
	}
})
