/*
 * @Descripttion : 路由信息
 * @version      :
 * @Author       : ml
 * @Date         : 2020-08-27 13:43:26
 * @LastEditors  : ml
 * @LastEditTime : 2020-09-03 16:12:20
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'login',
		meta: {
			title: '登录',
		},
		component: () => import('@/views/Login.vue'),
	},
]

const router = new VueRouter({
	routes,
})

export default router
