/*
 * @Descripttion :
 * @version      :
 * @Author       : ml
 * @Date         : 2020-08-27 13:43:26
 * @LastEditors: min
 * @LastEditTime: 2020-11-03 19:50:46
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'

// 图片预览
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'

Vue.use(Viewer, {
	defaultOptions: {
		zIndex: 9999,
		toolbar: true,
		url: 'data-src',
	},
})

// 路由处理
import './config/routerConfig'

Vue.config.productionTip = false

Vue.use(Antd)

import ZoomOnHover from "vue-zoom-on-hover";
Vue.use(ZoomOnHover);

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app')
