import axios from 'axios'

import { message } from 'ant-design-vue'
import router from '../router'

message.config({
	maxCount: 1,
})

// 创建axios实例
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API,
	timeout: 15000, // 请求超时时间
})

let isLogin = false
let isDownFile = false;

//http request 拦截器
service.interceptors.request.use(
	(config) => {
		if (config.url == 'login') {
			isLogin = true
		} else {
			isLogin = false
		}
		if (
			(config.method === 'post' && !config.data.json) ||
			(config.method === 'put' && !config.data.json)
		) {
			config.headers = {
				'Content-Type': 'application/x-www-form-urlencoded',
			}
			if (config.data) {
				let formData = new FormData()
				for (let i in config.data) {
					formData.append(i, config.data[i])
				}
				config.data = formData
			}
		} else {
			config.headers = {
				'Content-Type': 'application/json',
			}
		}

		if (config.params && config.params.downFile) {
			config.responseType = "blob";
			isDownFile = true;
		}

		if (config.data && config.data.json) {
			delete config.data.json
		}

		let token = localStorage.getItem('cbz_token')
		let appid = sessionStorage.getItem('cbz_appid')
		let timestamp = sessionStorage.getItem('cbz_timestamp')
		if (token) {
			config.headers = {
				token: token,
				appid: appid,
				timestamp: timestamp,
			}
		}

		return config
	},
	(error) => {
		return Promise.reject(error)
	}
)

//http response 拦截器
service.interceptors.response.use(
	(response) => {
		// 200:操作成功
		// 500:操作失败
		// 404:参数检验失败
		// 401:参数检验失败
		// 403:参数检验失败
		// console.log(response.data)
		if (isLogin) {
			return response.data
		} else {
			if (response.data.code === 200 || response.data.code >= 0 && (response.data.code !== 404 && response.data.code !== 401 && response.data.code !== 403 && response.data.code !== 500) || isDownFile) {
				return response.data
			} else {
				// 错误代码
				if (response.data.code === 401) {
					localStorage.removeItem("cbz_token");
					router.push("/");
				} else if (response.data.code === 403) {
					message.error('您没有权限操作，请联系管理员！')
					return Promise.reject(response.data)
				} else {
					message.error(response.data.message)
					return Promise.reject(response.data)
				}
			}
		}
	},
	(error) => {
		// console.log(error.response)
		if (error.response) {
			if (error.response.data.code === 401) {
				localStorage.removeItem("cbz_token");
				router.push("/");
			} else if (error.response.data.code == 403) {
				message.error('您没有权限操作，请联系管理员！')
			} else {
				if (error.response.data.code) {
					localStorage.removeItem('cbz_token')
					router.push("/");
				}
				message.error(error.response.data.message)
			}
		}

		return Promise.reject(error)
	}
)

export default {
	/**
	 * 封装get方法
	 * @param url
	 * @param data
	 * @returns {Promise}
	 */
	fetch(url, params = {}) {
		return new Promise((resolve, reject) => {
			service
				.get(url, {
					params,
				})
				.then((response) => {
					resolve(response)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},

	/**
	 * 封装post请求
	 * @param url
	 * @param data
	 * @returns {Promise}
	 */

	post(url, data = {}) {
		return new Promise((resolve, reject) => {
			service.post(url, data).then(
				(response) => {
					resolve(response)
				},
				(err) => {
					reject(err)
				}
			)
		})
	},

	/**
	 * 封装patch请求
	 * @param url
	 * @param data
	 * @returns {Promise}
	 */

	patch(url, data = {}) {
		return new Promise((resolve, reject) => {
			service.patch(url, data).then(
				(response) => {
					resolve(response)
				},
				(err) => {
					reject(err)
				}
			)
		})
	},

	/**
	 * 封装put请求
	 * @param url
	 * @param data
	 * @returns {Promise}
	 */

	put(url, data = {}) {
		return new Promise((resolve, reject) => {
			service.put(url, data).then(
				(response) => {
					resolve(response)
				},
				(err) => {
					reject(err)
				}
			)
		})
	},
	/**
	 * 封装delete请求
	 * @param url
	 * @param data
	 * @returns {Promise}
	 */

	delete(url, data = {}) {
		return new Promise((resolve, reject) => {
			service.delete(url, data).then(
				(response) => {
					resolve(response)
				},
				(err) => {
					reject(err)
				}
			)
		})
	},
	// /**
	//  * 封装delete请求
	//  * @param url
	//  * @param data
	//  * @returns {Promise}
	//  */

	// downFile(url, data = {}) {
	// 	return new Promise((resolve, reject) => {
	// 		service.get(url, data, responseType: 'blob',).then(
	// 			(response) => {
	// 				resolve(response)
	// 			},
	// 			(err) => {
	// 				reject(err)
	// 			}
	// 		)
	// 	})
	// },
}
